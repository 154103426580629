<template>
	<div class="app-container">
		<el-card class="filter-container" shadow="never">
			<div>
				<i class="el-icon-search"></i>
				<span>筛选搜索</span>
				<el-button style="float: right" @click="searchPermissionList()" type="primary" size="small" icon="el-icon-search">搜索</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="listQuery" size="small" label-width="140px">
					<el-form-item label="输入搜索："><el-input style="width: 203px" v-model="listQuery.name" placeholder="名称"></el-input></el-form-item>
					<!-- <el-form-item label="输入搜索：">
		        <el-input style="width: 203px" v-model="listQuery.pid" placeholder="上级ID"></el-input>
		      </el-form-item> -->
					<el-form-item label="状态：">
						<el-select v-model="listQuery.status" class="input-width" placeholder="显示" clearable>
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>

		<el-card class="operate-container" shadow="never">
			<i class="el-icon-tickets"></i>
			<span>菜单列表</span>
			<el-button class="btn-add" @click="handleAdd()" size="mini">添加</el-button>
		</el-card>

		<div class="table-container">
			<el-table :data="list" border v-loading="listLoading" style="width: 100%;">
				<!-- <el-table-column
		      prop="id"
		      header-align="center"
		      align="center"
		      label="ID">
		       <template slot-scope="scope">
		          {{scope.row.id}}
		      </template>
		    </el-table-column> -->
				<el-table-column prop="pid" header-align="center" align="center" label="上级名称">
					<template slot-scope="scope">
						{{ scope.row.pName ? scope.row.pName : '主菜单' }}
					</template>
				</el-table-column>
				<table-tree-column prop="name" header-align="center" treeKey="id" label="名称"></table-tree-column>
				<!-- <template slot-scope="scope">
		          {{scope.row.name }}
		      </template> -->
				<!-- <table-tree-column
		      prop="name"
		      header-align="center"
		      treeKey="id"
		      width="200"
		      label="名称">
		       <template slot-scope="scope">
		          {{scope.row.data.name }}
		      </template>
		    </table-tree-column> -->
				<el-table-column prop="type" header-align="center" align="center" label="类型">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.type == 1 && scope.row.pId == 0" size="small" type="success">主菜单</el-tag>
						<el-tag v-if="scope.row.type == 1 && scope.row.pId != 0" size="small" type="warning">子菜单</el-tag>
						<el-tag v-if="scope.row.type == 2" size="small" type="info">按钮</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="uri" header-align="center" align="center" :show-overflow-tooltip="true" label="菜单URL">
					<template slot-scope="scope">
						{{ scope.row.url }}
					</template>
				</el-table-column>
				<el-table-column prop="value" header-align="center" align="center" :show-overflow-tooltip="true" label="菜单备注">
					<template slot-scope="scope">
						{{ scope.row.remark }}
					</template>
				</el-table-column>
				<el-table-column prop="sort" header-align="center" align="center" :show-overflow-tooltip="true" label="菜单排序">
					<template slot-scope="scope">
						{{ scope.row.sort }}
					</template>
				</el-table-column>
				<el-table-column prop="sort" header-align="center" align="center" :show-overflow-tooltip="true" label="状态">
					<template slot-scope="scope">
						{{ scope.row.status == 0 ? '隐藏' : '显示' }}
					</template>
				</el-table-column>
				<!-- <el-table-column label="是否显示" width="100" align="center">
		      <template slot-scope="scope">
		        <el-switch
		          @change="handleShowStatusChange(scope.$index, scope.row)"
		          :active-value="1"
		          :inactive-value="0"
		          v-model="scope.row.status">
		        </el-switch>
		      </template>
		    </el-table-column> -->
				<el-table-column label="添加时间" align="center">
					<template slot-scope="scope">
						{{ scope.row.createdTxStamp }}
					</template>
				</el-table-column>
				<el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
					<template slot-scope="scope">
						<el-button size="mini" @click="handleAdd(scope.row)">编辑</el-button>
						<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%">
			<el-form :model="permission" :rules="rules" ref="permissionFrom" label-width="150px">
				<el-form-item label="类别：" prop="name">
					<el-radio-group v-model="type" @change="getPermissionCategoryList">
						<el-radio :label="0">主菜单</el-radio>
						<el-radio :label="1">子菜单</el-radio>
						<el-radio :label="2">按钮</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="菜单名称：" prop="name"><el-input style="width: 250px" v-model="permission.name"></el-input></el-form-item>
				<el-form-item label="菜单图标："><el-input style="width: 250px" v-model="permission.icon"></el-input></el-form-item>
				<!-- <el-form-item label="权限值：">
		     <el-input v-model="permission.value"></el-input>
		   </el-form-item> -->
				<el-form-item label="菜单url：" prop="url"><el-input style="width: 250px" v-model="permission.url"></el-input></el-form-item>
				<el-form-item label="上级菜单：" prop="pId" v-if="type > 0">
					<el-select placeholder="请选择上级菜单"  v-model="permission.pId">
						<el-option v-for="item in permissionCategoryOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单排序：" prop="sort"><el-input style="width: 250px" v-model="permission.sort" type="number"></el-input></el-form-item>
				<!-- <el-form-item label="菜单图像：" prop="logo">
		     <el-popover
		             placement="bottom-start"
		             width="450"
		             trigger="click"
		             @show="$refs['iconSelect'].reset()">
		       <IconSelect ref="iconSelect" @selected="selected" />
		       <el-input slot="reference" v-model="permission.icon" style="width: 450px;" placeholder="点击选择图标" readonly>
		         <svg-icon v-if="permission.icon" slot="prefix" :icon-class="permission.icon" class="el-input__icon" style="height: 32px;width: 16px;" />
		         <i v-else slot="prefix" class="el-icon-search el-input__icon"/>
		       </el-input>
		     </el-popover>
		   </el-form-item> -->
				<el-form-item label="菜单备注："><el-input type="textarea" :rows="2" style="width: 250px" placeholder="请输入菜单备注" v-model="textarea"></el-input></el-form-item>
				<el-form-item label="状态：">
					<el-radio-group v-model="permission.status">
						<el-radio :label="1">显示</el-radio>
						<el-radio :label="0">隐藏</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('permissionFrom')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { formatDate } from '@/plugins/date.js';
import TableTreeColumn from '@/components/table-tree-column/index';
const defaultPermission = {
	name: '',
	url: '',
	sort: '',
	pId: '',
	icon: '',
	status: ''
};
const defaultListQuery = {
	name: '',
	pid: '',
	status: 1
};
export default {
	components: {
		TableTreeColumn
	},
	data() {
		return {
			dialogTitle: '',
			permission: Object.assign({}, defaultPermission),
			dialogVisible: false,
			listQuery: {
				keyword: null
			},
			statusOptions: [
				{
					label: '显示',
					value: 1
				},
				{
					label: '隐藏',
					value: 0
				}
			],
			type: '',
			list: [],
			listQuery: Object.assign({}, defaultListQuery),
			textarea: '',
			permissionCategoryOptions: [],
			editId: '',
			rules: {
				name: [{ required: true, message: '请输入菜单名', trigger: 'blur' }],
				type: [{ required: true, message: '请选择菜单类型', trigger: 'blur' }],
				url: [{ required: true, message: '请输入菜单链接', trigger: 'blur' }],
				sort: [{ required: true, message: '请输入菜单排序', trigger: 'blur' }],
				pId: [{ required: true, message: '请选择上级菜单', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.getList();
	},
	filters: {
		formatTime(time) {
			if (time == null || time === '') {
				return 'N/A';
			}
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
		}
	},
	methods: {
		searchPermissionList() {
			this.getList();
		},
		handleAdd(row) {
			if (row) {
				this.dialogVisible = true;
				this.dialogTitle = '编辑菜单';
				this.getPermissionCategoryList();
				this.permission = {
					name: row.name,
					url: row.url,
					sort: row.sort,
					pId: row.pId,
					icon: row.icon,
					status: row.status,
					id: row.id
				};
				this.textarea = row.remark;
				if (row.pId == 0) {
					this.type = 0;
				} else if (row.type == 1) {
					this.type = 1;
				} else {
					this.type = 2;
				}
				// if(row.data.type ===1){
				//   this.listQuery.type = 1;
				// }else if(row.data.pId ===0){
				//   this.listQuery.type = 0;
				// }else{
				//   this.listQuery.type = 2;
				// }
			} else {
				this.dialogVisible = true;
				this.dialogTitle = '新增菜单';
				this.permission = Object.assign({}, defaultPermission);
				this.textarea = '';
				this.type = null;
			}
		},
		selVal(e) {
			this.permission.pId = e.value;
		},
		getPermissionCategoryList() {
			var that = this;
			this.permissionCategoryOptions.length = 0;
			if (this.type !== 2) {
				this.permission.type = 1;
			} else {
				this.permission.type = 2;
			}
			for (let i = 0; i < that.list.length; i++) {
				this.permissionCategoryOptions.push({
					label: that.list[i].name,
					value: that.list[i].id
				});
			}
			console.log(this.permissionCategoryOptions);
		},
		getList() {
			this.listLoading = true;
			this.$comjs.ajax.getAjax('/fa/admin/system/menu/getAllMenuList', this.listQuery, this, res => {
				this.listLoading = false;
				this.list = this.$comjs.treeDataTranslate(res.list, 'id', 'pId');
			});
		},
		handleDelete(index, row) {
			this.$confirm('是否要删除该条记录', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id: row.id,
					entity: 'FaAdminSysMenu'
				};
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					this.$message({
						message: '删除成功',
						type: 'success',
						duration: 1000
					});
					// this.getList();
					this.$emit('adds');
				});
				// deletePermission(data).then(response => {
				//   this.$message({
				//     message: '删除成功',
				//     type: 'success',
				//     duration: 1000
				//   });
				//   this.getList();
				// });
			});
		},
		onSubmit(formName) {
			var that = this;
			this.$refs[formName].validate(valid => {
				if (this.textarea) this.permission.remark = this.textarea;
				this.permission.type = this.type;
				if (this.type === 0){
					this.permission.pId = 0;
					this.permission.type = 1;
				} 
				// if(this.editId) this.permission.id = this.editId;
				if (valid) {
					let data = {
						dataObject: JSON.stringify(this.permission)
					};
					this.$confirm('是否提交数据', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$comjs.ajax.postAjax('/fa/admin/system/menu/menuSave', data, this, res => {
							this.dialogVisible = false;
							that.$refs[formName].resetFields();
							that.type = null;
							that.permission = Object.assign({}, defaultPermission);
							that.textarea = '';
							that.$message({
								message: '保存成功！',
								type: 'success',
								duration: 1000
							});
							that.getList();
							that.$emit('adds');
						});
					});
				}
			});
		}
	}
};
</script>
<style>
label {
	font-weight: 700;
}
</style>

<style scoped>
.app-container {
	padding: 20px;
}

.operate-container {
	margin-top: 20px;
}
.operate-container .btn-add {
	float: right;
}
</style>
